<template>
  <section>
    <InfoboxFilter />
    <Preloader v-if="callingAPI" />
    <InfoboxTable
      :infoboxes="infoboxes"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import InfoboxFilter from '../../components/filter/FilterInfobox'
import InfoboxTable from '@/components/table/InfoboxTable'

export default {
  name: 'InfoboxListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    infoboxes () {
      return this.$store.getters['infobox/list']
    },
    totalCount () {
      return this.$store.getters['infobox/totalCount']
    },
    page () {
      return this.$store.getters['infobox/page']
    }
  },
  components: {
    InfoboxTable,
    Preloader,
    InfoboxFilter
  },
  methods: {
    getInfoboxes () {
      this.$store.dispatch('infobox/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('infobox/setPage', page)
      this.getInfoboxes()
    }
  },
  mounted () {
    this.getInfoboxes()
  }
}
</script>
